/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const { isEmpty } = require('ramda');

// You can delete this file if you're not using it

exports.onInitialClientRender = () => {
  const hljsScript = document.createElement('script');

  const config = { attributes: true };

  const observer = new MutationObserver((mutationsList, observer) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes') {
        if (mutation.target.classList.contains('hljs')) {
          const { hash } = window.location;
          if (!isEmpty(hash)) {
            document.getElementById(hash.replace('#', '')).scrollIntoView();
            observer.disconnect();
          } else {
            observer.disconnect();
          }
        }
      }
    }
  });

  observer.observe(document.querySelector('pre code'), config);

  hljsScript.onload = function () {
    document.querySelectorAll('pre code').forEach(block => {
      window.hljs.highlightBlock(block);
    });
  };

  hljsScript.src =
    '//cdnjs.cloudflare.com/ajax/libs/highlight.js/10.1.1/highlight.min.js';

  document.head.appendChild(hljsScript); //or something of the likes
};
